import React from 'react';
import './footerStyles.scss';


const Footer = props => {

    const year = new Date().getFullYear()


    return(
        <footer className="footer">
            <p>&copy; Copyright {year} GenHybrid Systems, LLC.</p>
        </footer>
    );


};

export default Footer;
