import React from 'react';
import './milestoneCardStyles.scss';

const MilestoneCard = props => {

  return (
    <div className="milestone___card" style={{ height: "271px" }}>
      <h2 className="milestone__date">{props.date}</h2>
      <h4 className="milestone__event">{props.event}</h4>
      {
        props.url &&
        <h5 className="milestone__event">
          <a href={props.url} target="_blank">
            <button
              className={`primaryBtn primaryBtn--normal`}
              style={{ marginLeft: '5px', backgroundColor: 'rgb(0,183,189)', color: 'white' }}
            >
              Click to<br /> Learn More
            </button>
          </a>
        </h5>
      }
    </div>
  );


};

export default MilestoneCard;
